import api from "./api";

export default {
    login:(obj) => {
        return api.post(`v1/auth/login`, obj)
    },
    register:(obj) => {
        return api.post(`v1/auth/register`, obj)
    },
    me:() => {
        return api.get(`v1/auth/me`)
    },
    resetPassword:(obj) => {
        return api.post(`v1/auth/recovery-password`, obj)
    },
    checkResetPasswordToken:(token) => {
        return api.get(`v1/auth/reset-password/${token}`)
    },
    setNewPassword:(obj) => {
        return api.post(`v1/auth/new-password`, obj)
    },
    logout:() => {
        return api.post(`v1/auth/logout`)
    }
}