import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody,Container } from "reactstrap";

// Redux
import { withRouter, Link, useParams } from "react-router-dom";

// Formik with Yup
import { Formik, Form, Field } from 'formik';
import FormGroupFormik from '../../components/FormGroupFormik';
import LoadingButton from '../../components/LoadingButton';
import * as Yup from 'yup';

// import images
import logo from "../../assets/images/logo.png";

// api
import AuthApi from '../../api/authentication';

// toastr
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

  const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [tokenValidity, setTokenValidity] = useState(false)
    let { token } = useParams();


    useEffect(() => {
        checkTokenValidity(token)
    },[])

    async function checkTokenValidity(token) {
      const resp = await AuthApi.checkResetPasswordToken(token).catch(() => {
          setPageLoading(false)
      })
      if(resp){
        // toastr.success(resp.data.message)
        // resetForm()
      }
    }
    
    async function sendNewPassword(values, resetForm) {
      setLoading(true)
      const resp = await AuthApi.resetPassword(values).catch(() => {})
      if(resp){
        toastr.success(resp.data.message)
        resetForm()
      }
      setLoading(false)
    }
     
    return (
      <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                        <p>Reset your password.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    {!pageLoading && tokenValidity && (
                        <Formik
                            initialValues={{ 
                                password:'',
                                password_confirmation:''
                            }}
                            onSubmit={(values) => {
                                sendNewPassword(values)
                            }}
                            
                            validationSchema={validationSchema}
                            >
                            {({values, submitForm, setFieldValue, isValid, dirty, errors, touched}) => (
    
                                <Form className="form-horizontal">
    
                                    <FormGroupFormik errors={errors} touched={touched} labelName="password" name="password">
                                        <Field name="password" label="New Password" className={'form-control' + (errors.password && touched.password ? ' is-invalid ' : '')} placeholder="Enter password" type="password" />
                                    </FormGroupFormik>
    
                                    <FormGroupFormik errors={errors} touched={touched} labelName="Confirm new password" name="password_confirmation">
                                        <Field name="password_confirmation" label="Confirm new password" className={'form-control' + (errors.password_confirmation && touched.password_confirmation ? ' is-invalid ' : '')} placeholder="Confirm new password" type="password_confirmation" />
                                    </FormGroupFormik>
    
                                    <Row className="form-group">
                                    <Col className="text-right">
                                        <LoadingButton text="Update password" loading={loading} className="btn btn-primary w-md waves-effect waves-light" disabled={!(isValid && dirty)} onClick={() => submitForm() } />
                                    </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {!pageLoading && !tokenValidity && (
                        <h3>Token not valid</h3>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link
                    to="login"
                    className="font-weight-medium text-primary"
                  >
                    Login
                    </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Skote. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    );
  }

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .label('Password')
        .required()
        .min(8),
    password_confirmation: Yup.string()
        .label('Repeat Password')
        .required()
        .min(8)
        .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
})

export default withRouter(ResetPassword);
