import api from "./api";
import utility from '../utility'

export default {
    delete_table:(obj) => {
        return api.post(`v1/db/delete_table`, obj)
    },

    get_data:() => {
        return api.get(`v1/db/get_data`)
    }
}