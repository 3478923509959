import api from "./api";
import utility from '../utility'

export default {
    add_users_ticket:(obj) => {
        return api.post(`v1/tickets/add_users_ticket`, obj)
    },

    get_data:() => {
        return api.get(`v1/tickets/get_data`)
    }
}