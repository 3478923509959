import React, {useEffect, useState} from 'react';

import { Switch, BrowserRouter as Router,Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes , authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// APi
import AuthApi from "./api/authentication";

//Cookies
import Cookies from "js-cookie";

import {setToken, addUser} from  "./store/actions";

//import fakeBackend from './helpers/AuthType/fakeBackend';

// Activating fake backend
// fakeBackend();

const App = (props) => {

	const [loading, setLoading] = useState(true)
	const [auth, setAuth] = useState(false)
	
	async function getMe() {
		const resp = await AuthApi.me().catch(() => {})
		if(resp){
			props.addUser(resp.data.data)
			setAuth(true)
		}
		setLoading(false)
	}

	useEffect(() => {
		if(!props.user.user){
			const jwt = Cookies.get("jwt")
			if(jwt){
				props.setToken(jwt)
				getMe()
			}else{
				setLoading(false)
				setAuth(false)
			}
		}else{
			setAuth(true)
		}

		
	}, [props.user.user])

   function getLayout() {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

		const Layout = getLayout();

			const NonAuthmiddleware = ({
				component: Component,
				layout: Layout
			}) => (
					<Route
						render={props => {
						
						if(auth){
							return(
								<Redirect to={{ pathname: "/", state: { from: props.location } }} />
							)
						}

						return (
					     	   <Layout>
									<Component {...props} />
								</Layout>
							);
						}}
					/>
				);

		if(loading){
			return (
				<h1>Loading</h1>
			)
		}	

		  return (
		  		<React.Fragment>
				<Router>
					<Switch>
						{authRoutes.map((route, idx) => (
							<NonAuthmiddleware
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
							/>
						))}

						{userRoutes.map((route, idx) => (
							<Authmiddleware
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
							/>
						))}

					</Switch>
				</Router>
			</React.Fragment>
		
		  );
		}


const mapStateToProps = state => {
	return {
		layout: state.Layout,
		user: state.User
	};
};

export default connect(mapStateToProps, {setToken, addUser})(App);
