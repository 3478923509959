import React, { useEffect, useState } from 'react';
import {Row, Col, Card, CardBody, FormGroup, Input, Button} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';
import RecapCard from './RecapCard';

//Api
import AdminApi from '../../api/admin';

const _data = [
    {key:'school_partecipants', section:'School', subtitle:'per numero di partecipanti', lists:[]},
    {key:'school_partecipants_entries', section:'School', subtitle:'per numero di partecipanti qualificati', lists:[]},
    {key:'group_entries', section:'Group', subtitle:'per Minutes raccolti complessivamente', lists:[]},
    {key:'partecipants_entries', section:'Partecipant', subtitle:'per Minutes raccolti', lists:[]},
]

const Dashboard = (props) => {
    const [data, setData] = useState([])

    async function getData(){
        const resp = await AdminApi.dashboard().catch(() => {})
        if(resp){
            const d = _data.map(item => {

                let lists = [...resp.data.data[item.key]] 

                if(item.key === "school_partecipants_entries"){
                    lists = lists.map((list) => {
                        list.tempScores = list.scores 
                        list.scores = list.total 
                        return list
                    })
                }

                item.lists = lists
                return item
            })

            setData(d)
        }
    }

    useEffect(() => {
        getData()
    }, [])


    return(
        <div className="page-content">

            {/* Render Breadcrumb */}
            <Breadcrumbs title={props.t('Dashboard')} breadcrumbItem={props.t('Dashboard')} />

            <Row>

                {data.map((item, i) => 
                    <Col key={i} xl="3" sm="3">
                        <RecapCard data={item} />
                    </Col>
                )}
                
            </Row>
        </div>
    )
}

export default withNamespaces()(Dashboard);