import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

// Dashboard
import Results from "../pages/Results/index";
import Tasks from "../pages/Tasks/index";
import Users from "../pages/Users/index";
import Dashboard from "../pages/Dashboard/index";
import Styles from "../pages/Styles/index";
import Database from "../pages/Database/index";
import AddTickets from "../pages/AddTickets/index";

const dinamic_log = true
const userRoutes =dinamic_log ? [

	{ path: "/dashboard", exact: true, component: Dashboard },
	{ path: "/results", exact: true, component: Results },
	{ path: "/tasks", exact: true, component: Tasks },
	{ path: "/users", exact: true, component: Users },
	{ path: "/styles", exact: true, component: Styles},
	{ path: "/database", exact: true, component: Database},
	{ path: "/addtickets", exact: true, component: AddTickets},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]:[

	{ path: "/dashboard", exact: true, component: Dashboard },
	{ path: "/results", exact: true, component: Results },
	{ path: "/tasks", exact: true, component: Tasks },
	{ path: "/users", exact: true, component: Users },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
] ;

const authRoutes = [
	{ path: "/login", exact: true, component: Login },
	{ path: "/forgot-password",  component: ForgetPwd },
	{ path: "/reset-password/:token", component: ResetPassword },
];

export { userRoutes, authRoutes };
