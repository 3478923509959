import React from "react"

const LoadingButton = ({text, loading, className, disabled, onClick}) => {
    return (
        <button className={className ? className : 'btn btn-primary'} type={onClick ? 'button' : 'submit'} onClick={onClick} disabled={disabled}>
            {loading ? (
                <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> Loading</>
            ):(
                <>{text}</>
            )}
        </button>
    )
}

export default LoadingButton