import React, { useEffect, useState, useRef} from 'react';
import { withNamespaces } from 'react-i18next';
import { CSVLink, CSVDownload } from "react-csv";

import "./Database.css"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'


import DeleteTable from '../../api/delete_table';
import { set } from 'js-cookie';
const Database = () => {

    const [dataTables, setDataTables] = useState({})
    const [data, setData] = useState([])
    const [dataColumn, setDataColumn] = useState([])
    const [table, setTable] = useState('')
    const [csvData, setCsvData] = useState([])
    const [modal, setModal] = useState(false)
    const [check, setCheck] = useState('')



    useEffect(() => {
        // faccio una chiamata api per prendere i dati delle tabelle
        // i dati mi arriveranno così user_datas, users, teams, task_user, tasks, user_datas_column ...
        get_data()
    }, [])  

    const get_data = async () => {
        const resp = await DeleteTable.get_data().catch(() => {})
        setDataTables({...resp.data})
    }


    // creo un funzione che mi permette di creare una tabella

    const createTable = (data) => {
        setTable(data)
    }

    useEffect(() => {
        // i dati delle rispettive tabelle sono in dataTables, table è il nome della tabella
        // quindi devo prendere i dati di dataTables che hanno come nome table
        // e li salvo in una costante per creare una tabella
        if(table && dataTables[table]?.length) {
            if (!dataTables[table]?.length) {
                toastr.error('no table data')
                return
            }
            setData(dataTables[table])

            // creo le colonne da un oggetto in dataTables estraendo le chiavi
            setDataColumn(Object.keys(dataTables[table][0]))
            // creo il csv
            //console.log(dataTables[table].map(item => Object.values(item)))
            //console.log([...Object.keys(dataTables[table][0])])
            let csv = [Object.keys(dataTables[table][0]),...dataTables[table].map(item => Object.values(item))]
            //console.log(csv)
            setCsvData(csv)
    }
    }, [table,dataTables])


    const displayTable = dataColumn.length && data.length && (
        <table className='styled-table'>
            <thead>
                <tr>
                    {dataColumn.map((item, index) => (
                        <th key={index + 'header'}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        {Object.keys(item).map((key, index) => (
                            <td key={key + index}>
                                {typeof item[key] === 'object'
                                    ? JSON.stringify(item[key])
                                    : item[key]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );


    const deleDataTeble = async () => {
        const resp = await DeleteTable.delete_table({"table":table}).catch(() => {})
        if(resp.data.status === "success"){
            setModal(false)
            toastr.success('Table deleted successfully')
            get_data()
        }else{
            setModal(false)
            toastr.error('Table not deleted')
        }
    }


    return (
        <div className='db-container'>
            {modal &&
                <div className='modal-container'>
                    <div className='modal-database'>
                        <div className='modal-title'>Are you sure you want to delete the table {table}?</div>
                        <div className='modal-btn-container'>
                            <button className='btn-delete-no' onClick={()=> setModal(false)}>No</button>
                            <button className='btn-delete' onClick={deleDataTeble}>Yes</button>
                        </div>
                    </div>
                </div>
            }
            {table && 
                <div className='database-btn-container'>
                    <button className='btn-delete' onClick={()=> setModal(true)}>Delete Table </button>
                    <CSVLink data={csvData} filename={`${table}.csv`}>Download Csv</CSVLink>
                </div>
            }
            <div className='db-label-tabel'>
                <div className='db-label'>Rotary {table ? table : "" }</div>
                <div className='db-tabel'>
                    <div className={`db-tabel-label ${table === "user_datas" ? "blueviolet" : ""}`} onClick={()=> createTable("user_datas")}>Users Datas</div>
                    <div className={`db-tabel-label ${table === "users" ? "blueviolet" : ""}`} onClick={()=> createTable("users")}>Users</div>
                    <div className={`db-tabel-label ${table === "teams" ? "blueviolet" : ""}`} onClick={()=> createTable("teams")}>Teams</div>
                    <div className={`db-tabel-label ${table === "task_user" ? "blueviolet" : ""}`} onClick={()=> createTable("task_user")}>Task User</div>
                    <div className={`db-tabel-label ${table === "tasks" ? "blueviolet" : ""}`} onClick={()=> createTable("tasks")}>Tasks</div>
                </div>
            </div>
            {displayTable ?displayTable: null}
        </div>
    )
}


export default withNamespaces()(Database);