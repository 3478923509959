import axios from 'axios';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import store from '../store/index'

const baseDomain = process.env.REACT_APP_APIURL
const baseURL = `${baseDomain}/api/`

const instance =  axios.create({
    baseURL
});

instance.interceptors.response.use(function(response){  
    if(response.headers.version){
        if(localStorage.getItem('rotary_v')){
            if(response.headers.version !== localStorage.getItem('rotary_v')){
                toastr.info(`App updated to the latest version: ${response.headers.version}`)
                setTimeout(() => {
                    localStorage.setItem('rotary_v', response.headers.version)
                    window.location.reload(true)     
                }, 3000);
            }
        }else{
            localStorage.setItem('rotary_v', response.headers.version)
        }
    }

    return response
    
}, function (err) {   
    switch (err.response.status) {
        case 401:
            
            let errors = []

            if('status' in err.response.data){
                if(err.response.data.status == 'errors'){
                    for (const key in err.response.data.message) {
                        if (err.response.data.message.hasOwnProperty(key)) {
                            const element = err.response.data.message[key];
                            element.forEach(element => {
                                errors.push(element)
                            });
                        }
                    }
                }else if('error'){
                    errors.push(err.response.data.message)
                }

                if(errors.length > 0){
                    toastr.error(errors.join(','), 'Error')
                }
            }else if('message' in err.response.data){
                toastr.error(err.response.data.message, 'Error')
                if(err.response.data.message == 'Unauthenticated.'){
                    store.dispatch({ type: "SET_TOKEN", payload:null })
                    // Cookie.remove('jwt')
                    // delete instance.defaults.headers.common['Authorization']
                }
            }

            break;
        default:
            break;
    }

    return Promise.reject(err);
  });

export default instance