import React, { useState } from 'react';
import { Row, Col, Alert, Card, CardBody,Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// Formik with Yup
import { Formik, Form, Field } from 'formik';
import FormGroupFormik from '../../components/FormGroupFormik';
import LoadingButton from '../../components/LoadingButton';
import * as Yup from 'yup';

// import images
import logo from "../../assets/images/logo.png";

// api
import AuthApi from '../../api/authentication';

// toastr
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

  const ForgetPasswordPage = (props) => {
    const [loading, setLoading] = useState(false)


    async function requestPasswordReset(values, resetForm) {
      setLoading(true)
      const resp = await AuthApi.resetPassword(values).catch(() => {})
      if(resp){
        toastr.success(resp.data.message)
        resetForm()
      }
      setLoading(false)
    }
     
    return (
      <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Forgot Password</h5>
                        <p>Recover your password.</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    <Formik
                      initialValues={{ 
                          email:''
                      }}
                      onSubmit={(values, actions) => {
                          requestPasswordReset(values, actions.resetForm)
                      }}
                      
                      validationSchema={validationSchema}
                      >
                      {({values, submitForm, setFieldValue, isValid, dirty, errors, touched}) => (

                          <Form className="form-horizontal">

                              <FormGroupFormik errors={errors} touched={touched} labelName="Email" name="email">
                                  <Field name="email" label="Email" className={'form-control' + (errors.email && touched.email ? ' is-invalid ' : '')} placeholder="Enter email" type="email" />
                              </FormGroupFormik>

                              <Row className="form-group">
                                <Col className="text-right">
                                  <LoadingButton text="Reset" loading={loading} className="btn btn-primary w-md waves-effect waves-light" disabled={!(isValid && dirty)} onClick={() => submitForm() } />
                                </Col>
                              </Row>
                          </Form>
                      )}
                  </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link
                    to="login"
                    className="font-weight-medium text-primary"
                  >
                    Login
                    </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    );
  }

const validationSchema = Yup.object().shape({
  email: Yup.string()
      .email()
      .required(),
})

export default withRouter(
  connect(null, null)(ForgetPasswordPage)
);
