import React, { useEffect, useState, useRef } from 'react';
import {Row, Col, Card, CardBody, FormGroup, Button, Input} from "reactstrap";
import { useHistory } from 'react-router'
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import FormGroupFormik from "../../components/FormGroupFormik"; 
// import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

import { Table, Space } from 'antd';
import 'antd/dist/antd.css';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import EditTaskModal from './EditTaskModal';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Api
import TaskApi from "../../api/task"; 
import ExportApi from "../../api/export"; 

import {CopyToClipboard} from 'react-copy-to-clipboard';

const Tasks = (props) => {
    const [tasks, setTasks] = useState([])
    const [pagination, setPagination] = useState({current: 1,pageSize: 10})
    const [loading, setLoading] = useState(true)
    const [searchObj, setSearchObj] = useState({...pagination})
    const [typing, setTyping] = useState({ typing: false, typingTimeout: 0})
    const [alertTrash, setAlertTrash] = useState(null)
    const [editObject, setEditObject] = useState({})
    const [modal, SetModal] = useState(false)
    const [existPreregistration, setExistPreregistration] = useState(false)
    const [existQualification, setExistQualification] = useState(false)

    const inputFile = useRef(null) 
    const history = useHistory()
    useEffect(() => {
        reloadTable()
    }, [searchObj])

    async function reloadTable(){
        setLoading(true)
        const resp = await TaskApi.index(searchObj).catch(() =>{})
        if(resp){
            setTasks(resp.data.data.data)
            setPagination({current: resp.data.data.current_page,pageSize: pagination.pageSize, total:resp.data.data.total})
            setLoading(false)
            resp.data.task_types.forEach(element => {
                if(element.task_type === 'Pre Registration'){
                    setExistPreregistration(true)
                }
                if(element.task_type === 'Qualification'){
                    setExistQualification(true)
                }
            });
        }
    }

    function search(e){
        const value = e.target.value
        if(typing.typingTimeout){
            clearTimeout(typing.typingTimeout)
        }

        setTyping({
            typing: false,
            typingTimeout: setTimeout(() => {
                const _searchObj = {... searchObj}
                _searchObj.search = value
                _searchObj.page = 1
                setSearchObj(_searchObj)
            }, 500)   
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Score',
            dataIndex: 'score',
            sorter: true,
        },
        {
            title: 'Tickets',
            dataIndex: 'tickets',
            sorter: true,
        },
        {
            title: 'Qr Code',
            dataIndex: 'qr_code',
            render: (text, record) => (
                <CopyToClipboard text={`https://app.westonrotaryrun.com/qr-url-reader/${record.qr_code}`}
                    onCopy={() => toastr.info('Copied')}>
                    <button type="button" className="btn btn-secondary">Copy to clipboard</button>
                </CopyToClipboard>
            ),
        },
        {
            title: 'Qr Code',
            dataIndex: 'qr_code',
            render: (text, record) => (
                <Space size="middle">
                  <a onClick={() => download(record.qr_code)}>Download</a>
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (row, record, a) => (
                <Space size="middle">
                {row.can_delete  ? (
                    <a onClick={() => setAlertTrash(record)}>Delete</a>
                ) : ('')}
              </Space>
            ),
          },
      ];
      
      function onChange(_pagination, filters, sorter, extra) {

        const _searchObj = {... searchObj}
        _searchObj.page = _pagination.current

        if(sorter.order !== undefined){
            _searchObj.order_by = `${sorter.field}:${sorter.order == 'ascend' ? 'asc' : 'desc'}`
        }else{  
            delete _searchObj.order_by
        }

        setSearchObj(_searchObj)
        
      }

    async function confirmDelete(){
        const resp = await TaskApi.delete(alertTrash).catch(() =>{})
        if(resp){
            toastr.success('Task successfully deleted.')
            setAlertTrash(null)
            reloadTable()
        }          
        history.go(0)
    }

    function toggleModal(status, obj = {}){
        setEditObject(obj)
        SetModal(status)
    }

    async function download(qr_code){
        const resp = await TaskApi.downloadQrCode(qr_code).catch(() => {})

        if(resp){
            let a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(
                new File([resp.data], `${qr_code}.pdf`)
            );

            a.download =`${qr_code}.pdf` ;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    async function downloadCsv(){
        setLoading(true)
        const resp = await ExportApi.tasks(searchObj).catch(() => {})
        if(resp){
            let byteArray = new Uint8Array(resp.data);
            let a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(
                new Blob([byteArray], { type: 'application/octet-stream' })
            );

            a.download ='tasks.csv' ;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        setLoading(false)
    }

    async function onFileChange(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        if(file){
            setLoading(true)
            const resp = await TaskApi.uploadCsv(file).catch((error) => {
                console.error(error);
            })

            if(resp){
                toastr.success(resp.data.message)
            }
            setLoading(false)
        }
    }

    async function exportQrCodes() {
        const resp = await TaskApi.exportQrCodes().catch((error) => {
            console.error(error);
        })

        if(resp){
            toastr.success(resp.data.message)
        }
    }
      

    return(
        <React.Fragment>
            {alertTrash !== null && (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                            confirmDelete()
                        }
                    }
                    onCancel={() =>
                        {
                            setAlertTrash(null)
                        }
                    }
                >
                    You want to delete the task?
                </SweetAlert>
            )}
            <div className="page-content">
                <EditTaskModal modal={modal} existQualification={existQualification} existPreregistration={existPreregistration} toggleModal={toggleModal} tag={editObject} reloadTable={reloadTable} />

                {/* Render Breadcrumb */}
                <Breadcrumbs title={props.t('Tasks')} breadcrumbItem={props.t('Tasks')} />

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Row style={{justifyContent:'space-between'}}>
                                    <Col xl="8" lg="8" md="8">
                                        <Button onClick={() => SetModal(true)} type="button" color="primary">
                                            <i className="mdi mdi-plus"></i> Add new task
                                        </Button>
                                        <Button style={{marginLeft:20}} onClick={downloadCsv} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Export CSV
                                        </Button>
                                        <Button style={{marginLeft:20}} onClick={exportQrCodes} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Export Qr Codes
                                        </Button>
                                        <input accept=".csv" type='file' id='file' onChange={onFileChange} ref={inputFile} style={{display: 'none'}}/>
                                        <Button style={{marginLeft:20}} onClick={() => {
                                            if(inputFile && inputFile.current){
                                                inputFile.current.click();
                                            }
                                        }} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Import CSV
                                        </Button>
                                        <Button style={{marginLeft:20}} onClick={() => {
                                            window.open(`${process.env.REACT_APP_APIURL}/storage/tasks-csv-demo.csv`, '_blank')
                                        }} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Demo CSV
                                        </Button>
                                    </Col>
                                    <Col xl="4" lg="4" md="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                className="colorpicker-default"
                                                placeholder="Search name..." 
                                                onChange={search}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Table style={{overflow:'auto'}} rowKey={record => record.id} columns={columns} dataSource={tasks} loading={loading} onChange={onChange} pagination={pagination} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(Tasks);