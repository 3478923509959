import React from "react"
import { Label, FormGroup } from "reactstrap";
import {ErrorMessage } from 'formik';
const FormGroupFormik = (props) => {
    return(
        <FormGroup style={props.style} className={props.errors[props.name] && props.touched[props.name] ? ' text-danger ' : ''}>
            <Label for="basicpill-firstname-input14">{props.labelName}</Label>
            {props.children}
            <ErrorMessage className="invalid-feedback" name={props.name}/>
        </FormGroup>
    )
}


export default FormGroupFormik