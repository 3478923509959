import api from "./api";
import utility from '../utility'

export default {
    index:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/admin/users?${_search}`)
    },
    show:(id) => {
        return api.get(`v1/admin/users/${id}`)
    },
    resend:(obj) => {
        return api.post(`v1/admin/users/resend-registration-email`, obj)
    },
    get_grades:() => {
        return api.get(`v1/grades`)
    },
    get_schools:() => {
        return api.get(`v1/schools`)
    },
    get_teams:() => {
        return api.get(`v1/teams`)
    },
    update:(id, obj) => {
        return api.put(`v1/admin/users/${id}`, obj)
    },
}