import api from "./api";
import utility from '../utility'

export default {
    index:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/admin/tasks?${_search}`)
    },
    store:(obj) => {
        return api.post(`v1/admin/tasks`, obj)
    },
    downloadQrCode:(code) => {
        return api.get(`v1/admin/tasks/download-qr-code/${code}`, {'responseType': 'blob'})
    },
    exportQrCodes:() => {
        return api.post(`v1/admin/tasks/export-qrcodes`)
    },
    delete:(obj) => {
        return api.delete(`v1/admin/tasks/${obj.id}`)
    },
    uploadCsv:(file) => {
        let formData = new FormData();
        formData.append('file', file);
        return api.post(`v1/admin/tasks/import-csv`, formData, {
            headers: {
                "Content-type": "multipart/form-data",
            }
        })
    },
}