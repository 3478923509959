import { ADD_USER, REMOVE_USER, SET_TOKEN } from './actionTypes';

export const addUser = (user) => {
    return {
        type: ADD_USER,
        payload: user
    }
}

export const removeUser = () => {
    return {
        type: REMOVE_USER,
        payload: null
    }
}

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        payload: {token:token}
    }
}
