import React, { useEffect, useState } from 'react';
import {Row, Col, Card, CardBody, FormGroup, Input, Button} from "reactstrap";

const RecapCard = (props) => {
    return(
        <Card>
            <CardBody>
                <Row>
                    <Col lg="12">
                        <div>
                            <h5 className="text-truncate">{props.data.section}</h5>
                            <h5 className="text-truncate" style={{fontSize:13, marginBottom:20}}>{props.data.subtitle}</h5>
                            {props.data.lists.map((item, index) => 
                                <ul key={index} className="list-inline mb-0">
                                
                                    <li className="list-inline-item mr-3">
                                        <h5 className="font-size-14" id="amountTooltip">
                                            {index+1}°
                                        </h5>
                                    </li>
                                    <li className="list-inline-item">
                                        <h5 className="font-size-14" id="duedateTooltip">
                                            {item.name}
                                        </h5>
                                    </li>
                                    <li className="list-inline-item">
                                        <h5 className="font-size-14" id="duedateTooltip">
                                            {item.scores}
                                        </h5>
                                    </li>
                                </ul>    
                            )}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default RecapCard;

