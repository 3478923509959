import { ADD_USER, REMOVE_USER, SET_TOKEN } from './actionTypes';
import Api from "../../../api/api";
import Cookies from "js-cookie";

const initialState = {
    user:null,
    token:null
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case ADD_USER:
            state = {... action.payload}
            break;
        case REMOVE_USER:
            state = {... action.payload}
            break;
        case SET_TOKEN:
            let user = state.user
            if(action.payload.token){
                Api.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`; 
            }else{
                delete Api.defaults.headers.common['Authorization']
                Cookies.remove('jwt')
                user = null
            }

            state = { ...state, token:  action.payload.token, user};
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default user;