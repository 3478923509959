import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';

// users
import user from '../../../assets/images/user.png';

//Api
import AuthApi from "../../../api/authentication";

//Actions
import {setToken} from "../../../store/actions";

const ProfileMenu = (props) => {
    const history = useHistory();
   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);
  
   const [username, setusername] = useState("Admin");

   async function logout(){
    const resp = await AuthApi.logout().catch(() => {})
    if(resp){
        props.setToken(null)
        history.push('/login')
    }
   }

  return (
    <React.Fragment>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src={user} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{username}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <a onClick={logout} className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{props.t('Logout')}</span>
                        </a>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}

export default withRouter(connect(null, {setToken })(withNamespaces()(ProfileMenu)));

