import React, { useEffect, useState } from 'react';
import {Row, Col, Card, CardBody, FormGroup, Input, Button} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

//antd
import { Table } from 'antd';
import 'antd/dist/antd.css';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Api
import UserTaskApi from "../../api/user_task"; 
import ExportApi from "../../api/export"; 

const Results = (props) => {
    const [tasks, setTasks] = useState([])
    const [pagination, setPagination] = useState({current: 1,pageSize: 10})
    const [loading, setLoading] = useState(true)
    const [searchObj, setSearchObj] = useState({...pagination})
    const [typing, setTyping] = useState({ typing: false, typingTimeout: 0})
    useEffect(() => {
        reloadTable()
    }, [searchObj])

    async function reloadTable(){
        setLoading(true)
        const resp = await UserTaskApi.index(searchObj).catch(() =>{})
        if(resp){
            setTasks(resp.data.data.data)
            setPagination({current: resp.data.data.current_page,pageSize: pagination.pageSize, total:resp.data.data.total})
            setLoading(false)
        }
    }

    function search(e){
        const value = e.target.value
        if(typing.typingTimeout){
            clearTimeout(typing.typingTimeout)
        }

        setTyping({
            typing: false,
            typingTimeout: setTimeout(() => {
                const _searchObj = {... searchObj}
                _searchObj.search = value
                _searchObj.page = 1
                setSearchObj(_searchObj)
            }, 500)   
        })
    }
    

    const columns = [
        {
            title: 'Player ID',
            dataIndex: 'player_id',
            sorter: true,
        },
        {
            title: 'Nickname',
            dataIndex: 'nickname',
            sorter: true,
        },
        {
            title: 'Sum of tasks',
            dataIndex: 'tasks',
            sorter: true,
        },
        {
            title: 'Sum of scores',
            dataIndex: 'scores',
            sorter: true,
        },
        {
            title: 'Sum of tickets',
            dataIndex: 'tickets',
            sorter: true,
        }
      ];
      
      function onChange(_pagination, filters, sorter, extra) {
        const _searchObj = {... searchObj}
        _searchObj.page = _pagination.current

        if(sorter.order !== undefined){
            _searchObj.order_by = `${sorter.field}:${sorter.order == 'ascend' ? 'asc' : 'desc'}`
        }else{  
            delete _searchObj.order_by
        }

        setSearchObj(_searchObj)
        
      }

      async function downloadCsv(){
        setLoading(true)
        const resp = await ExportApi.results(searchObj).catch(() => {})
        if(resp){
            let byteArray = new Uint8Array(resp.data);

            if(byteArray.length == 89){
                var string = Utf8ArrayToStr(byteArray)
                var json = JSON.parse(string)

                toastr.success(json.message)
            }else{
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='results.csv' ;

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);          
            }


            
        }
        setLoading(false)
    }
      
    async function playersTickets(){
        setLoading(true)
        const resp = await ExportApi.playersTickets(searchObj).catch(() => {})
        if(resp){
            let byteArray = new Uint8Array(resp.data);

            if(byteArray.length == 89){
                var string = Utf8ArrayToStr(byteArray)
                var json = JSON.parse(string)

                toastr.success(json.message)
            }else{
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='players-lists.txt' ;

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);          
            }


            
        }
        setLoading(false)
    }
      
    function Utf8ArrayToStr(array) {
        var out, i, len, c;
        var char2, char3;
    
        out = "";
        len = array.length;
        i = 0;
        while(i < len) {
        c = array[i++];
        switch(c >> 4)
        {
          case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
          case 12: case 13:
            // 110x xxxx   10xx xxxx
            char2 = array[i++];
            out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
            break;
          case 14:
            // 1110 xxxx  10xx xxxx  10xx xxxx
            char2 = array[i++];
            char3 = array[i++];
            out += String.fromCharCode(((c & 0x0F) << 12) |
                           ((char2 & 0x3F) << 6) |
                           ((char3 & 0x3F) << 0));
            break;
        }
        }
    
        return out;
    }

    async function exportResults() {
        const resp = await UserTaskApi.exportReportPDF().catch((error) => {
            console.error(error);
        })

        if(resp){
            toastr.success(resp.data.message)
        }
    }

    return(
        <React.Fragment>
            <div className="page-content">
                {/* <EditStyleModal modal={modal} toggleModal={toggleModal} tag={editObject} reloadTable={reloadTable} /> */}

                {/* Render Breadcrumb */}
                <Breadcrumbs title={props.t('Results')} breadcrumbItem={props.t('Results')} />

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Row style={{justifyContent:'space-between'}}>
                                    <Col xl="7" lg="7" md="7">
                                        <Button onClick={downloadCsv} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Export CSV
                                        </Button>
                                        <Button style={{marginLeft:20}} onClick={playersTickets} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Export Players TXT
                                        </Button>
                                        <Button style={{marginLeft:20}} onClick={exportResults} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Export Results
                                        </Button>
                                    </Col>
                                    <Col xl="4" lg="4" md="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                className="colorpicker-default"
                                                placeholder="Search nickname or player ID..." 
                                                onChange={search}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Table style={{overflow:'auto'}} rowKey={record => record.id} columns={columns} dataSource={tasks} loading={loading} onChange={onChange} pagination={pagination} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(Results);