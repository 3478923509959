import React, { useEffect, useState, useRef} from 'react';
import { withNamespaces } from 'react-i18next';
import Multiselect from 'multiselect-react-dropdown';
import "./AddTickets.css"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import  ApiTickets from '../../api/add_tickets';
import user from '../../api/user';

const AddTickets = () => {

    // tickets
    const [optionsTickets, setOptionsTickets] = useState([])
    const [selectedValueTickets, setSelectedValueTickets] = useState([])

    // users
    const [optionsUsers, setOptionsUsers] = useState([])
    const [selectedValueUsers, setSelectedValueUsers] = useState([])


    // tasks-user
    const [tasksUser, setTasksUser] = useState([])

    // abilità salvataggio

    const [canSave, setCanSave] = useState(false)


    // salvo gli errori da correggere

    const [errorTask, setErrorTask] = useState([])


    

    
    const onSelectTickets = (selectedList, selectedItem) => {
        
        setSelectedValueTickets(selectedList)

    }
    
    const onRemoveTickets = (selectedList, removedItem)=> {
       
       setSelectedValueTickets(selectedList)
    }

    const onSelectUsers = (selectedList, selectedItem) => {
        
        setSelectedValueUsers(selectedList)
    }

    const onRemoveUsers = (selectedList, removedItem)=> {
         
            setSelectedValueUsers(selectedList)
    }

    useEffect(() => {
        // faccio una chiamata api per prendere i dati delle select
        get_data()
    }, [])

    const get_data = async () => {
        // const resp = await DeleteTable.get_data().catch(() => {})
        // setDataTables({...resp.data})

        const resp = await ApiTickets.get_data().catch(() => {})
        setOptionsTickets(resp.data.tasks)
        setOptionsUsers(resp.data.user)
        setTasksUser(resp.data.task_user)
    }

    const add_task = async () => {
        let tasks = [...selectedValueTickets]
        let users = [...selectedValueUsers]


        const resp = await ApiTickets.add_users_ticket({users:users, tasks:tasks}).catch(() => {})
        //console.log(resp.data)


        if (resp.data.status === 'success') {
            toastr.success('add task')
            setSelectedValueTickets([])
            setSelectedValueUsers([])
            get_data()
        }else {
            toastr.error('error')
        }
    }



    useEffect(() => {
        // quando cambiano i valori della select faccio un controllo per vedere se il task è già stato assegnato all'utente
        checkTaskUser()
    }, [selectedValueTickets, selectedValueUsers])

    const checkTaskUser = () => {
        let task_user=[]
        console.log(selectedValueTickets)// id id tickets
        console.log(selectedValueUsers) // id id users
        if (selectedValueTickets.length && selectedValueUsers.length) {
            let lengthOfValueTickets = selectedValueTickets.length
            let lengthOfValueUsers = selectedValueUsers.length

            let userHaveTasck = []

            // controllo se il task è già stato assegnato all'utentec itero su tutti i task e su tutti gli utenti
            for (let i = 0; i < lengthOfValueTickets; i++) {
                for (let j = 0; j < lengthOfValueUsers; j++) {
                    tasksUser.forEach(item => {
                        if (item.task_id === selectedValueTickets[i].id && item.user_id === selectedValueUsers[j].id) {
                            userHaveTasck.push(item)
                        }
                    })
                }
            }

            if (userHaveTasck.length) {
                //task_user.push(userHaveTasck[0])
                task_user = [...userHaveTasck]
                userHaveTasck = []
            }


            if (task_user.length) {
                // se entra qui vuol dire che ci sono task già assegnati e non posso salvare
                setCanSave(false)

                // ricavo il nome del task e del user per ogni item di task_user per visualizzarlo in un messaggio di errore
                let task = []
                let user = []
                for (let i = 0; i < task_user.length; i++) {

                    optionsTickets.forEach(item => {
                        if (item.id === task_user[i].task_id) {
                            task.push(item.name)
                        }
                    })

                    optionsUsers.forEach(item => {
                        if (item.id === task_user[i].user_id) {
                            user.push(item.nickname)
                        }
                    })

                }
                
                // creo un array di oggetti con task e user per visualizzarlo in un messaggio di errore
                let newError = []
                for (let i = 0; i < task.length; i++) {
                    newError = [...newError, {"task":task[i], "user":user[i]}]
                }
                setErrorTask([...newError])
            }else {
                //console.log('task user non presente')
                setErrorTask([])
                setCanSave(true)
            }
        }

    }


        


    return (
        <div className='add-tickets-container'>
            <div className="container-title">
            <h3 className='left-title'><b>ADD TICKETS</b></h3>
            <h3 className='right-title'>Add Tickets / Add Tickets</h3>
            </div>
            <div className="container-select" >
                <div className="first-select">
                    <label htmlFor="tickets">Task</label>
                    <Multiselect
                        options={optionsTickets} // Options to display in the dropdown
                        selectedValues={selectedValueTickets} // Preselected value to persist in dropdown
                        onSelect={onSelectTickets} // Function will trigger on select event
                        onRemove={onRemoveTickets} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    />
                </div>
                <div className="second-select">
                    <label htmlFor="users">Users</label>
                    <Multiselect
                        options={optionsUsers} // Options to display in the dropdown
                        selectedValues={selectedValueUsers} // Preselected value to persist in dropdown
                        onSelect={onSelectUsers} // Function will trigger on select event
                        onRemove={onRemoveUsers} // Function will trigger on remove event
                        displayValue="nickname" // Property name to display in the dropdown options
                />
                </div>
            </div>
            {canSave &&
            <div className="container-button">
                <button className="button" onClick={add_task}>Add</button>
            </div>
            }
            {errorTask.length > 0 &&
                <div className="container-error">
                    <h3>Task for this user already done</h3>
                    <div className="container-error-list">
                        {errorTask.map((item, index) => {
                            return (
                                <div className="error-list" key={index}>
                                    <div className="error-list-task">{item.task}</div>
                                    <div className="error-list-user">{item.user}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default withNamespaces()(AddTickets);