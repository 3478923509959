import api from "./api";
import utility from '../utility'

export default {
    index:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/admin/task-users?${_search}`)
    },
    exportReportPDF:() => {
        return api.post('v1/admin/export-reports-pdf')
    }
}