import React, { useEffect, useState } from 'react';

// import { Row, Col, CardBody, Card, Alert,Container } from "reactstrap";
import { Container, Row, Col, Card, CardBody, FormGroup, InputGroup, Label, Input, Button } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';

// Formik with Yup
import { Formik, Form, Field } from 'formik';
import FormGroupFormik from '../../components/FormGroupFormik';
import LoadingButton from '../../components/LoadingButton';
import * as Yup from 'yup';

// actions
import { setToken, addUser } from '../../store/actions';

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";

//Api
import AuthApi from "../../api/authentication";

//Cookies
import Cookies from "js-cookie";

 const Login = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)

    // handleValidSubmit
    async function  handleValidSubmit(values) {
        setLoading(true)
        const resp = await AuthApi.login(values).catch(() => {})
        if(resp){
            Cookies.set("jwt", resp.data.data.token);
            props.addUser(resp.data.data.user)
            props.setToken(resp.data.data.token)
            history.push('/results')
        }else{
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
        <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden">
                            <div className="bg-soft-primary">
                                <Row>
                                    <Col className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p>Sign in to continue.</p>
                                        </div>
                                    </Col>
                                    {/* <Col className="col-5 align-self-end">
                                        <img src={profile} alt="" className="img-fluid" />
                                    </Col> */}
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <div>
                                    <Link to="/">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src={logo} alt="" className="rounded-circle" height="34" />
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="p-2">

                                    <Formik
                                        initialValues={{ 
                                            nickname:'', password:'' 
                                        }}
                                        onSubmit={(values, actions) => {
                                            handleValidSubmit(values)
                                        }}
                                        
                                        validationSchema={validationSchema}
                                        >
                                        {({values, submitForm, setFieldValue, isValid, dirty, errors, touched}) => (

                                            <Form className="form-horizontal">

                                                <FormGroupFormik errors={errors} touched={touched} labelName="Nickname" name="nickname">
                                                    <Field name="nickname" label="Nickname" className={'form-control' + (errors.nickname && touched.nickname ? ' is-invalid ' : '')} placeholder="Enter nickname" type="text" />
                                                </FormGroupFormik>
                        
                                                <FormGroupFormik errors={errors} touched={touched} labelName="Password" name="password">
                                                    <Field name="password" label="Password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid ' : '')} placeholder="Enter Password" />
                                                </FormGroupFormik>

                                                <div className="mt-3">
                                                    <LoadingButton text="Log In" loading={loading} className="btn btn-primary btn-block waves-effect waves-light" disabled={!(isValid && dirty)} onClick={() => submitForm() } />
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </CardBody>
                        </Card>
                        {/* <div className="mt-5 text-center">
                            <p>© {new Date().getFullYear()} Skote. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
    );
}

const validationSchema = Yup.object().shape({
    nickname: Yup.string()
        .required(),
    password: Yup.string()
        .required(),
})

export default withRouter(connect(null, { setToken, addUser })(Login));

