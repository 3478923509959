import api from "./api";
import utility from '../utility'

export default {
    users:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/exports/users?${_search}`, {responseType: 'arraybuffer'})
    },
    tasks:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/exports/tasks?${_search}`, {responseType: 'arraybuffer'})
    },
    results:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/exports/results?${_search}`, {responseType: 'arraybuffer'})
    },
    playersTickets:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/exports/players-tickets?${_search}`, {responseType: 'arraybuffer'})
    },
}