import React, { useEffect, useState } from 'react';
import {Row, Col, Card, CardBody, FormGroup, Button, Input} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

import { Table, Space } from 'antd';
import 'antd/dist/antd.css';

//SweetAlert
import EditUserModal from './EditUserModal';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Api
import UserApi from "../../api/user"; 
import ExportApi from "../../api/export"; 

const Users = (props) => {
    const [users, setUsers] = useState([])
    const [pagination, setPagination] = useState({current: 1,pageSize: 10})
    const [loading, setLoading] = useState(true)
    const [searchObj, setSearchObj] = useState({...pagination})
    const [typing, setTyping] = useState({ typing: false, typingTimeout: 0})
    const [editObject, setEditObject] = useState({})
    const [modal, SetModal] = useState(false)
    const [grades, setGrades] = useState([])
    const [schools, setSchools] = useState([])
    const [teams, setTeams] = useState([])

    useEffect(() => {
        reloadTable()
    }, [searchObj])

    useEffect(() => {
        getGrades()
        getSchools()
        getTeams()
    },[])

    async function reloadTable(){
        setLoading(true)
        const resp = await UserApi.index(searchObj).catch(() =>{})
        if(resp){
            setUsers(resp.data.data.data)
            setPagination({current: resp.data.data.current_page,pageSize: pagination.pageSize, total:resp.data.data.total})
            setLoading(false)
        }
    }

    async function getGrades(){
        const resp = await UserApi.get_grades().catch(() => {})
        if(resp){
            setGrades(resp.data.data)
        }
    }
    
    async function getSchools(){
        const resp = await UserApi.get_schools().catch(() => {})
        if(resp){
            setSchools(resp.data.data)
        }
    }
   
    async function getTeams(){
        const resp = await UserApi.get_teams().catch(() => {})
        if(resp){
            setTeams(resp.data.data)
        }
    }

    function search(e){
        const value = e.target.value
        if(typing.typingTimeout){
            clearTimeout(typing.typingTimeout)
        }

        setTyping({
            typing: false,
            typingTimeout: setTimeout(() => {
                const _searchObj = {... searchObj}
                _searchObj.search = value
                _searchObj.page = 1
                setSearchObj(_searchObj)
            }, 500)   
        })
    }

    const columns = [
        {
            title: 'Player ID',
            dataIndex: 'player_id',
            render: (text, record) => (
                <>{record.user_data?.player_id}</>
            ),
            sorter: true,
        },
        {
            title: 'Nickname',
            dataIndex: 'nickname',
            sorter: true,
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            render: (text, record) => (
                <>{record.user_data?.first_name}</>
            ),
            sorter: true,
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            render: (text, record) => (
                <>{record.user_data?.last_name}</>
            ),
            sorter: true,
        },
        {
            title: 'Group',
            dataIndex: 'group',
            render: (text, record) => (
                <>{record.team && (
                    <>{record.team.name}</>
                )}</>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                <a onClick={() => toggleModal(true, record)}>View</a>
                <a onClick={() => resendEmail(record)}>Resend</a>
              </Space>
            ),
          },
      ];
      
      function onChange(_pagination, filters, sorter, extra) {
        const _searchObj = {... searchObj}
        _searchObj.page = _pagination.current

        if(sorter.order !== undefined){
            _searchObj.order_by = `${sorter.field}:${sorter.order == 'ascend' ? 'asc' : 'desc'}`
        }else{  
            delete _searchObj.order_by
        }

        setSearchObj(_searchObj)
        
      }

    function toggleModal(status, obj = {}, reload = false){
        setEditObject(obj)
        SetModal(status)
        if(reload){
            reloadTable()
        }
    }

    async function resendEmail(user) {
        const resp = await UserApi.resend(user).catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
        }
    }

    async function downloadCsv(){
        setLoading(true)
        const resp = await ExportApi.users(searchObj).catch(() => {})
        if(resp){
            let byteArray = new Uint8Array(resp.data);

            if(byteArray.length == 89){
                var string = Utf8ArrayToStr(byteArray)
                var json = JSON.parse(string)

                toastr.success(json.message)
            }else{
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='users.csv' ;

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);            
            }
        }

        setLoading(false)
    }

    function Utf8ArrayToStr(array) {
        var out, i, len, c;
        var char2, char3;
    
        out = "";
        len = array.length;
        i = 0;
        while(i < len) {
        c = array[i++];
        switch(c >> 4)
        {
          case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
          case 12: case 13:
            // 110x xxxx   10xx xxxx
            char2 = array[i++];
            out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
            break;
          case 14:
            // 1110 xxxx  10xx xxxx  10xx xxxx
            char2 = array[i++];
            char3 = array[i++];
            out += String.fromCharCode(((c & 0x0F) << 12) |
                           ((char2 & 0x3F) << 6) |
                           ((char3 & 0x3F) << 0));
            break;
        }
        }
    
        return out;
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <EditUserModal modal={modal} grades={grades} teams={teams} schools={schools} toggleModal={toggleModal} user={editObject} reloadTable={reloadTable} />

                {/* Render Breadcrumb */}
                <Breadcrumbs title={props.t('Users')} breadcrumbItem={props.t('Users')} />

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Row style={{justifyContent:'space-between'}}>
                                    <Col xl="4" lg="4" md="4">
                                        <Button onClick={downloadCsv} type="button" color="primary">
                                            <i className="mdi mdi-arrow-collapse-down"></i> Export CSV
                                        </Button>
                                    </Col>
                                    <Col xl="4" lg="4" md="4">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                className="colorpicker-default"
                                                placeholder="Search nickname or player id..." 
                                                onChange={search}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Table style={{overflow:'auto'}} 
                                    rowKey={record => record.id} 
                                    columns={columns} 
                                    dataSource={users} 
                                    loading={loading} 
                                    onChange={onChange} 
                                    pagination={pagination} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(Users);