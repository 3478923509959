import React, { useEffect, useState } from "react"
import { Row, Col, Modal } from "reactstrap";
import { Formik, Form, Field } from "formik";



import LoadingButton from "../../components/LoadingButton"; 
import FormGroupFormik from "../../components/FormGroupFormik"; 
import * as Yup from "yup";
import TaskApi from "../../api/task";



import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const taskType = [
    {value: 1, label: 'Base Task'},
    {value: 2, label: 'Pre Registration'},
    {value: 3, label: 'Post Registration'},
    {value: 4, label: 'Qualification'},
]

const EditTaskModal = ({modal, toggleModal, task, reloadTable,existPreregistration,existQualification}) => {


    const [loading, SetLoading] = useState(false)
    const [modalTask, setModalTask] = useState({})
    const [typeTicket, setTypeTicket] = useState('Base Task')
    const [date, setDate] = useState(new Date().toISOString().split('T')[0])
    const [errorForm, setErrorForm] = useState(false)

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() =>{
        if(modal){
            removeBodyCss()
        }
        if(task){
            setModalTask(task)
        }
    }, [modal])

    async function editTaks(values) {
        values.task_type = typeTicket
        values.date = date
        SetLoading(true)
        const resp = await TaskApi.store(values).catch(() =>{})
        if(resp){
            toastr.success('Task created successfully.')
            setTypeTicket('Base Task')
            toggleModal(false)
            reloadTable()  
        }
        SetLoading(false)
    }

    const taskChange = (e) => {
        if(existQualification && e.target.value === 'Qualification'){
            toastr.error('You can only have one Qualification task.')
            setErrorForm(true)
            return
        }
        
        if(!existPreregistration && e.target.value === 'Post Registration'){
            toastr.error('You need to create a Pre Registration task first.')
            setErrorForm(true)
            return
        }

        if(existPreregistration && e.target.value === 'Pre Registration'){
            toastr.error('You can only have one Pre Registration task.')
            setErrorForm(true)
            return
        }

        setErrorForm(false)
        setTypeTicket(e.target.value)
    }



    return (
        <Modal
            size="lg"
            isOpen={modal}
            toggle={() => { toggleModal(false) }}
            centered={true}
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">{modalTask.id ? 'Edit Task' : 'New Task'}</h5>
                <button
                type="button"
                onClick={() => { toggleModal(false) } }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        id:modalTask.id || '',
                        name: modalTask.name || '',
                        score: modalTask.score || '',
                        tickets: modalTask.tickets || '',
                        typeTicket: modalTask.typeTicket || '',
                    }}
                    validationSchema={formSchema}
                    onSubmit={values => {
                        editTaks(values)
                    }}
                    >
                    {({errors, touched, setFieldValue, values, submitForm, isValid, dirty}) => (
                    <Form>
                        <Row>
                            <Col lg="12">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Task name" name="name">
                                    <Field type="text" name="name" className={'form-control' + (errors.name && touched.name ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Get Fit Challenge Minutes" name="score">
                                    <Field type="number" name="score" className={'form-control' + (errors.score && touched.score ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroupFormik errors={errors} touched={touched} labelName="N. Tickets" name="tickets">
                                    <Field type="number" name="tickets" className={'form-control' + (errors.tickets && touched.tickets ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Task type" name="type">
                                    <select onChange={taskChange}>
                                        {taskType.map((type, index) => (
                                            <option key={index} value={type.label}>{type.label}</option>
                                        )
                                        )}

                                    </select>
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        { typeTicket === 'Pre Registration' && !existPreregistration ?
                        <Row>
                            <Col lg="12">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Preregistraion date " name="date">
                                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)}  />
                                </FormGroupFormik>
                            </Col>
                        </Row> : null
                        }
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => toggleModal(false) }>Close</button>
                            { errorForm ? null :
                                <LoadingButton text="Save" loading={loading} disabled={!(isValid && dirty)} onClick={() => submitForm() } /> 
                                
                            }
                        </div>   
                    </Form>)}
                </Formik>


            </div>
        </Modal>
    )
}

const formSchema = Yup.object().shape({
    name: Yup
        .string()
        .required(),
    score: Yup
        .number()
        .required(),
    tickets: Yup
        .number()
        .required()
})

export default EditTaskModal