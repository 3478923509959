import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import './Style.css'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
// importo il file file.php che si trova in questa caretella

 const Styles = (props) => {
  

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [selectedFaq, setSelectedFaq] = useState(null);
    const fileInputRef = useRef(null);
    const fileInputRefPdf = useRef(null);
    const fileInputRefFaq = useRef(null);
    const [register, setRegister] = useState('<></>');
    const [recovery, setRecovery] = useState('<></>');
    const [recoverySubject, setRecoverySubject] = useState('');
    const [registerSubject, setRegisterSubject] = useState('');
    const [recoverySubjectNick, setRecoverySubjectNick] = useState('');
    const [nickname_recovery, setNickname_recovery] = useState('<></>');
  
    const handleFileChange = (event,type) => {
      console.log(type)
      if(type == 'logo'){
        setSelectedFile(event.target.files[0]);
      }
      else if(type == 'faq'){
        setSelectedFaq(event.target.files[0]);
      }else {
        setSelectedPdf(event.target.files[0]);
      }
      
    };
  
    const handleUpload = (type) => {
        if (selectedFile || selectedPdf || selectedFaq) {
          // Qui puoi eseguire l'upload del file utilizzando una libreria o chiamata API

          // Esempio di invio del file utilizzando la libreria Axios
          const formData = new FormData();
          formData.append('file',type == "logo" ? selectedFile :type == "faq" ?selectedFaq: selectedPdf);
          axios.post(`${process.env.REACT_APP_APIURL}/api/v1/upload/${type}`, formData)
          .then(response => {
            toastr.success("Upload completed successfully")
          })
          .catch(error => {
            toastr.error("Upload Error")
          });
        } else {
          toastr.warning("please select a file")
        }
      };


    const handleSelectFile = () => {
      fileInputRef.current.click();
    };
    const handleSelectFilepdf = () => {
      fileInputRefPdf.current.click();
    };

    const handleSelectFilefaq = () => {
      fileInputRefFaq.current.click();
    };

    useEffect(() => {
     /* const fetchFileContent = async () => {
        try {
          const response = await fetch(file);
          const textContent = await response.text();
          setFilePhp(textContent);
          console.log(textContent)
        } catch (error) {
          console.error('Errore nella lettura del file PHP: ', error);
        }
      };
  
      fetchFileContent();*/
      const fetchFileContent = async () => {
      axios.get(`${process.env.REACT_APP_APIURL}/api/v1/send_template/get_template`)
      .then(response => {
        //setFilePhp(response.data)
        console.log(response.data)
        setRegister(response.data.register)
        setRecovery(response.data.recovery)
        setNickname_recovery(response.data.nickname_recovery)
        response.data.subject_email.forEach(el => {
          if(el.mail_type == "register"){
            setRegisterSubject(el.mail_object)
          }
          if(el.mail_type == "recovery_pw"){
            setRecoverySubject(el.mail_object)
          }
          if(el.mail_type == "recovery_nick"){
            setRecoverySubjectNick(el.mail_object)
          }
        })
      })
      .catch(error => {
        toastr.error("Upload Error")
      });
    }
    fetchFileContent()
    }, []);

    const saveFilePhp = async (type) => {
      axios.post(`${process.env.REACT_APP_APIURL}/api/v1/send_template/save_template`,{
        type:type,
        register:register,
        recovery:recovery,
        nickname_recovery:nickname_recovery,
        registerSubject:registerSubject,
        recoverySubject:recoverySubject,
        recoverySubjectNick:recoverySubjectNick
      })
      .then(response => {
        toastr.success("Upload completed successfully")
      })
      .catch(error => {
        toastr.error("Upload Error")
      });
    }


    return (
      <>
          <div className="page-content">
              <div className='style-container'>
                <div className="logo-container">
                    <h1 className="title_upload">Upload Logo</h1>
                    <input         
                        type="file"
                        ref={fileInputRef}
                        onChange={(e)=>handleFileChange(e,"logo")}
                        className="upload-file"
                    />
                    <div className='btn-container-upload'>
                      <button onClick={handleSelectFile}>Select File </button>
                      <span className='file-name-upload'>{selectedFile?.name ? selectedFile.name : ''}</span>
                      <button onClick={()=>handleUpload('logo')}>Upload</button>
                    </div>
                  </div>
                  <div className="logo-container">
                    <h1 className="title_upload">Upload waiver</h1>
                    <input         
                        type="file"
                        ref={fileInputRefPdf}
                        onChange={(e)=>handleFileChange(e,"waiwer")}
                        className="upload-file"
                    />
                    <div className='btn-container-upload'>
                      <button onClick={handleSelectFilepdf}>Select File</button>
                      <span className='file-name-upload'>{selectedPdf?.name ? selectedPdf.name : ''}</span>
                      <button onClick={()=>handleUpload('pdf')}>Upload</button>
                    </div>
                  </div>
                  <div className="logo-container">
                    <h1 className="title_upload">Upload faq</h1>
                    <input         
                        type="file"
                        ref={fileInputRefFaq}
                        onChange={(e)=>handleFileChange(e,"faq")}
                        className="upload-file"
                    />
                    <div className='btn-container-upload'>
                      <button onClick={handleSelectFilefaq}>Select File</button>
                      <span className='file-name-upload'>{selectedFaq?.name ? selectedFaq.name : ''}</span>
                      <button onClick={()=>handleUpload('faq')}>Upload</button>
                    </div>
                  </div>
              </div>
              <div className='delimiter'></div>
              <div className='container_mail'>
                <div className="row_editor_container">
                  <div className="file_column">
                    <h1 className="title_upload_php">File register</h1>
                    <div className='editor_email_container'>
                      <textarea className='file_tag' rows={30} cols={40}  value={register} onChange={e => setRegister(e.target.value)} ></textarea>
                      <div className='file_view' dangerouslySetInnerHTML={{ __html: register }} />
                    </div>
                    <div className='editor_subject_email'>
                      <h3>Update email subject</h3>
                      <textarea className='file_tag' rows={4} cols={40}  value={registerSubject} onChange={e => setRegisterSubject(e.target.value)} ></textarea>
                    </div>
                    <button onClick={()=>saveFilePhp("register")}>Save</button>
                  </div>
                </div>
              </div>
              <div className='delimiter'></div>
              <div className='container_mail'>
                <div className="row_editor_container">
                  <div className="file_column">
                    <h1 className="title_upload_php">File recovery password</h1>
                    <div className='editor_email_container'>
                      <textarea className='file_tag' rows={30} cols={40}  value={recovery} onChange={e => setRecovery(e.target.value)} ></textarea>
                      <div className='file_view' dangerouslySetInnerHTML={{ __html: recovery }} />
                    </div>
                    <div className='editor_subject_email'>
                      <h3>Update email subject</h3>
                      <textarea className='file_tag' rows={4} cols={40}  value={recoverySubject} onChange={e => setRecoverySubject(e.target.value)} ></textarea>
                    </div>
                    <button onClick={()=>saveFilePhp("recovery")}>Save</button>
                  </div>
                </div>
              </div>
              <div className='delimiter'></div>
              <div className='container_mail'>
                <div className="row_editor_container">
                  <div className="file_column">
                    <h1 className="title_upload_php">File recovery nickname</h1>
                    <div className='editor_email_container'>
                      <textarea className='file_tag' rows={30} cols={40}  value={nickname_recovery} onChange={e => setNickname_recovery(e.target.value)} ></textarea>
                      <div className='file_view' dangerouslySetInnerHTML={{ __html: nickname_recovery }} />
                    </div>
                    <div className='editor_subject_email'>
                      <h3>Update email subject</h3>
                      <textarea className='file_tag' rows={4} cols={40}  value={recoverySubjectNick} onChange={e => setRecoverySubjectNick(e.target.value)} ></textarea>
                    </div>
                    <button onClick={()=>saveFilePhp("nickname_recovery")}>Save</button>
                  </div>
                </div>
              </div>
            

          </div>
      </>
    )

}


export default withNamespaces()(Styles);