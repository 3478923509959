import React, { useEffect, useState } from "react"
import { Row, Col, Modal } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import FormGroupFormik from "../../components/FormGroupFormik"; 
import { Table, Space } from 'antd';
import 'antd/dist/antd.css';
import UserApi from "../../api/user";
import LoadingButton from '../../components/LoadingButton';

// toastr
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const EditUserModal = ({modal, toggleModal, user, reloadTable, grades, schools, teams}) => {
    const [modalUser, setModalUser] = useState({})
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(false)
      
    const columns = [
        {
          title: 'D/T',
          dataIndex: 'created_at',
        },
        {
          title: 'Task Name',
          dataIndex: 'name',
          render: (text, record) => (
            <>{record.task.name}</>
            ),
        },
        {
          title: 'Score',
          dataIndex: 'score',
        },
        {
          title: 'Tickets',
          dataIndex: 'tickets',
        },
    ];

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() =>{
        if(modal){
            removeBodyCss()
            getData()
        }
        if(user){
            setModalUser(user)
        }
    }, [modal])

    async function getData(){
        const resp = await UserApi.show(user.id).catch(() => {})
        if(resp){
            setTasks(resp.data.data.tasks)
        }
    }
    
    async function updateData(values){
        setLoading(true)
        const resp = await UserApi.update(user.id,values).catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
            toggleModal(false, false, true)
        }

        setLoading(false)
    }

    return (
        <Modal
            size="lg"
            isOpen={modal}
            toggle={() => { toggleModal(false) }}
            centered={true}
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">View User</h5>
                <button
                type="button"
                onClick={() => { toggleModal(false) } }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        nickname:user.nickname || '',
                        email:user.email || '',
                        first_name:user.user_data?.first_name || '',
                        last_name:user.user_data?.last_name || '',
                        school_id:user.user_data?.school_id || '',
                        parent_guardian_first_name:user.user_data?.parent_guardian_first_name || '',
                        parent_guardian_last_name:user.user_data?.parent_guardian_last_name || '',
                        parent_guardian_phone:user.user_data?.parent_guardian_phone || '',
                        city:user.user_data?.city || '',
                        grade_id:user.user_data?.grade_id || '',
                        team_id:user.team_id || '',
                        address:user.user_data?.address || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        updateData(values)
                    }}
                    >
                    {({errors, touched, setFieldValue, values, submitForm, isValid, dirty}) => (
                    <Form>
                        <Row>
                            <Col lg="6">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Nickname" name="nickname">
                                    <Field  type="text" name="nickname" className={'form-control' + (errors.nickname && touched.nickname ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                            <Col lg="6">
                                <FormGroupFormik errors={errors} touched={touched} labelName="E-mail" name="email">
                                    <Field  type="email" name="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroupFormik errors={errors} touched={touched} labelName="First Name" name="first_name">
                                    <Field  type="text" name="first_name" className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                            <Col lg="6">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Last Name" name="last_name">
                                    <Field  type="text" name="last_name" className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <FormGroupFormik errors={errors} touched={touched} labelName="School Name" name="school_id">
                                    <Field as="select" name="school_id" style={{width:'100%', height:37}}>
                                         <option value="">No school selected</option>
                                        {schools.map(school => <option key={school.id} value={school.id}>{school.name}</option>)}
                                    </Field>
                                </FormGroupFormik>
                            </Col>
                            <Col lg="4">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Grade" name="grade_id">
                                    <Field as="select" name="grade_id" style={{width:'100%', height:37}}>
                                         <option value="">No grade selected</option>
                                        {grades.map(grade => <option key={grade.id} value={grade.id}>{grade.name}</option>)}
                                    </Field>
                                </FormGroupFormik>
                            </Col>
                            <Col lg="4">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Group" name="team_id">
                                    <Field as="select" name="team_id" style={{width:'100%', height:37}}>
                                         <option value="">No group selected</option>
                                        {teams.map(team => <option key={team.id} value={team.id}>{team.name}</option>)}
                                    </Field>
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Parent-guardian First Name" name="parent_guardian_first_name">
                                    <Field  type="text" name="parent_guardian_first_name" className={'form-control' + (errors.parent_guardian_first_name && touched.parent_guardian_first_name ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                            <Col lg="4">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Parent-guardian Last Name" name="parent_guardian_last_name">
                                    <Field  type="text" name="parent_guardian_last_name" className={'form-control' + (errors.parent_guardian_last_name && touched.parent_guardian_last_name ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                            <Col lg="4">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Parent-guardian Phone" name="parent_guardian_phone">
                                    <Field  type="text" name="parent_guardian_phone" className={'form-control' + (errors.parent_guardian_phone && touched.parent_guardian_phone ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroupFormik errors={errors} touched={touched} labelName="City" name="city">
                                    <Field  type="text" name="city" className={'form-control' + (errors.city && touched.city ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                            <Col lg="6">
                                <FormGroupFormik errors={errors} touched={touched} labelName="Address" name="address">
                                    <Field  type="text" name="address" className={'form-control' + (errors.address && touched.address ? ' is-invalid ' : '')} />
                                </FormGroupFormik>
                            </Col>
                        </Row>
                        <Row style={{marginTop:50}}>
                            <Col>
                                <Table style={{overflow:'auto'}} rowKey={record => record.id} dataSource={tasks} columns={columns} pagination={{pageSize: 5}} />;
                            </Col>
                        </Row>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => toggleModal(false) }>Close</button>
                            <LoadingButton text="Save changes" loading={loading} className="btn btn-primary waves-effect waves-light" disabled={!(isValid && dirty)} onClick={() => submitForm() } />
                        </div>   
                    </Form>)}
                </Formik>


            </div>
        </Modal>
    )
}


const validationSchema = Yup.object().shape({
    nickname: Yup.string()
      .label('Nickname')
      .required(),
    email: Yup.string()
      .label('Email')
      .email()
      .required(),
    first_name: Yup.string()
      .label('First Name')
      .required(),
    last_name: Yup.string()
      .label('Last Name')
      .required(),
    school_id: Yup.string()
      .label('School Name')
      .required(),
    grade_id: Yup.string()
      .label('Grades')
      .required(),
    team_id: Yup.string()
      .label('Teams')
      .required(),
    parent_guardian_first_name: Yup.string()
      .label('Parent-guardian First Name')
      .required(),
    parent_guardian_last_name: Yup.string()
      .label('Parent-guardian Last Name')
      .required(),
    parent_guardian_phone: Yup.string()
      .label('Parent-guardian Phone')
      .required(),
    city: Yup.string()
      .label('City')
      .required(),
    address: Yup.string()
      .label('Address')
      .required()
      .required()
  })

export default EditUserModal